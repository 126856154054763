<template>
  <div>
    <base-section id="serviceprovidercustomermarket-account">
      <v-container>
        <v-row justify="space-between">
          <v-col cols="12" md="12">
            <base-info-card :title="$t('customerSP.mainTitle')" />
            <!-- We display the request -->
            <kdw-request-management />
            <!-- @alertSnackBarMessage="alertSnackBarMessage($event)" -->
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </div>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
// import { RequestService } from '@/services/Request'
// import { provideExpirationDate } from '@/helpers/GenericFunctions'
// import { downloadBlob } from '@/helpers/GenericFunctionsS3Download'
// import { formatAWSDateTimeIntoDate } from '@/helpers/Formatter'
// import { Logger } from 'aws-amplify'
// import { FILEHEADERQUOTATION } from '@/services/File'
// import { AnswerStateMachine, answerStateTranslation, answerState } from '@/services/StateMachine'
// import { checkSPConsent, checkSPBanned } from '@/services/Consent'
// import { fetchLegalAndCustomerNote } from '@/services/Service'
// import { DateTime } from 'luxon'
// import { sendEmail } from '@/services/commonTools'

// const logger = new Logger('ServiceProviderCustomerMarketAccount')

@Component({ name: "ServiceProviderCustomerMarketAccount" })
class ServiceProviderCustomerMarketAccount extends Vue {
  /*
    alertSnackBarMessage (event) {
      this.$root.kdwsnackbar.alert(event.message, event.snackbarTimeout)
    }
    */
}

export default ServiceProviderCustomerMarketAccount;
</script>
